define("discourse/plugins/discourse-translator/discourse/components/translated-post", ["exports", "@glimmer/component", "@ember/service", "@ember/template", "discourse/components/conditional-loading-spinner", "discourse-common/helpers/i18n", "@ember/component", "@ember/template-factory"], function (_exports, _component, _service, _template, _conditionalLoadingSpinner, _i18n, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class TranslatedPost extends _component.default {
    static shouldRender(args) {
      return args.post.isTranslated || args.post.isTranslating;
    }
    static #_ = (() => dt7948.g(this.prototype, "siteSettings", [_service.service]))();
    #siteSettings = (() => (dt7948.i(this, "siteSettings"), void 0))();
    get loading() {
      return this.post.isTranslating;
    }
    get isTranslated() {
      return this.post.isTranslated;
    }
    get post() {
      return this.args.outletArgs.post;
    }
    get translatedText() {
      return this.post.translatedText;
    }
    get translatedTitle() {
      return this.post.translatedTitle;
    }
    static #_2 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <div class="post-translation">
          <ConditionalLoadingSpinner
            class="post-translation"
            @condition={{this.loading}}
            @size="small"
          >
            <hr />
            {{#if this.translatedTitle}}
              <div class="topic-attribution">
                {{this.translatedTitle}}
              </div>
            {{/if}}
            <div class="post-attribution">
              {{i18n
                "translator.translated_from"
                language=this.post.detectedLang
                translator=this.siteSettings.translator
              }}
            </div>
            <div class="cooked">
              {{htmlSafe this.post.translatedText}}
            </div>
          </ConditionalLoadingSpinner>
        </div>
      
    */
    {
      "id": "8WUaY4xo",
      "block": "[[[1,\"\\n    \"],[10,0],[14,0,\"post-translation\"],[12],[1,\"\\n      \"],[8,[32,0],[[24,0,\"post-translation\"]],[[\"@condition\",\"@size\"],[[30,0,[\"loading\"]],\"small\"]],[[\"default\"],[[[[1,\"\\n        \"],[10,\"hr\"],[12],[13],[1,\"\\n\"],[41,[30,0,[\"translatedTitle\"]],[[[1,\"          \"],[10,0],[14,0,\"topic-attribution\"],[12],[1,\"\\n            \"],[1,[30,0,[\"translatedTitle\"]]],[1,\"\\n          \"],[13],[1,\"\\n\"]],[]],null],[1,\"        \"],[10,0],[14,0,\"post-attribution\"],[12],[1,\"\\n          \"],[1,[28,[32,1],[\"translator.translated_from\"],[[\"language\",\"translator\"],[[30,0,[\"post\",\"detectedLang\"]],[30,0,[\"siteSettings\",\"translator\"]]]]]],[1,\"\\n        \"],[13],[1,\"\\n        \"],[10,0],[14,0,\"cooked\"],[12],[1,\"\\n          \"],[1,[28,[32,2],[[30,0,[\"post\",\"translatedText\"]]],null]],[1,\"\\n        \"],[13],[1,\"\\n      \"]],[]]]]],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[],false,[\"if\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-translator/discourse/components/translated-post.js",
      "scope": () => [_conditionalLoadingSpinner.default, _i18n.default, _template.htmlSafe],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = TranslatedPost;
});